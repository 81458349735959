import {axiosKtb} from '@/plugins/axios';
import {ConfirmarTablonModal} from '@/entidades/KTB/RegistroInformacion/ConfirmarTablon';
import { Editar,Save } from '@/Generico/Conexion';

export async function GenerapSapKtb(confirmar:ConfirmarTablonModal)
{
    return Editar<ConfirmarTablonModal>('recepcion/GenerarSapManual',confirmar,axiosKtb);
}


export async function GenerapSapConsumo(listado:number[])
{

    return Save<number[]>('consumo/GenerarSapManual',listado,axiosKtb);
}
