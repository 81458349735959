import FiltroFecha from './FiltroFecha';
import {RecepcionReporteKtb,RecepcionReporteKtbDetalle} from './RecepcionMaderaConsuta';
import { axiosKtb } from '@/plugins/axios';
import { ObtenerPost2,Obtener, DescargarFileList,DescargarFile } from '@/Generico/Conexion';
import {ConsumoQuery,ConsumoResponse} from './ConsumoResponseReporte';

//#region RECEPCION DE MADERA

export async function ObtenerRecepcionFecha(filtros:FiltroFecha)
{
    return ObtenerPost2<RecepcionReporteKtb,FiltroFecha>('Reporte/ObtenerRecepcionFecha',filtros,axiosKtb);
}

export async function ObtenerRecepcionDetalle(recepcionId:number,formatoId:number)
{
    return Obtener<RecepcionReporteKtbDetalle>(`Reporte/ObtenerRecepcionDetalle/${recepcionId}/${formatoId}`,axiosKtb);
}


export async function DescargarExcelDespacho(despachos:Array<RecepcionReporteKtb>)
{
    return DescargarFileList<RecepcionReporteKtb>('reporte/descargarexceldespacho',despachos,axiosKtb);
}
export async function DescargarExcelDespachoDetalle(despachos:RecepcionReporteKtb)
{
    return DescargarFile<RecepcionReporteKtb>('reporte/descargarexceldespachodetalle',despachos,axiosKtb);
}
//#endregion

//#region CONSUMO
export async function ObtenerConsumoFecha(filtros:ConsumoQuery)
{
    return ObtenerPost2<ConsumoResponse,ConsumoQuery>('Reporte/ObtenerConsumoFecha',filtros,axiosKtb);
}
export async function ObtenerConsumoDetalle(consumoId:number)
{
    return Obtener<RecepcionReporteKtbDetalle>(`Reporte/ObtenerConsumoDetalle/${consumoId}`,axiosKtb);
}

export async function DescargarExcelConsumo(consumo:Array<ConsumoResponse>)
{
    return DescargarFileList<ConsumoResponse>('reporte/DescargarExcelConsumo',consumo,axiosKtb);
}
export async function DescargarExcelConsumoDetalle(consumo:ConsumoResponse)
{
    return DescargarFile<ConsumoResponse>('reporte/descargarexcelconsumodetalle',consumo,axiosKtb);
}
//#endregion