
































































































































import { Vue, Component } from "vue-property-decorator";
import moment from "moment";
import HaciendaItem from "@/entidades/Plantacion/HaciendaItem";
import { State, Action } from "vuex-class";
import { Planta } from "@/entidades/Maestro/Planta";
import {
  ObtenerRecepcionFecha,
  ObtenerRecepcionDetalle,
  DescargarExcelDespacho,
  DescargarExcelDespachoDetalle,
} from "@/entidades/KTB/Reporte/ConexionDbReporte";
import Loading from "@/entidades/Sistema/Loading";
import Alerta from "@/entidades/Sistema/Alerta";
import DespachoReporteQuery from "@/entidades/KTB/Reporte/FiltroFecha";
import {
  RecepcionReporteKtb,
  RecepcionReporteKtbDetalle,
} from "@/entidades/KTB/Reporte/RecepcionMaderaConsuta";
import {
  ConfirmarTablonModalDetalle,
  ConfirmarTablonModal,
} from "@/entidades/KTB/RegistroInformacion/ConfirmarTablon";
import { GenerapSapKtb } from "@/entidades/Sap/GenerarSapGlobal";
@Component({
  name: "RecepcionTally",
  components: {
    tabla: () => import("@/components/General/TablaGeneralFinal.vue"),
  },
})
export default class RecepcionTally extends Vue {
  @State("plantaAsignada", { namespace: "authModule" }) plantaAsignada!: Array<
    Planta
  >;
  @Action("changeLoading", { namespace: "sistemaModule" })
  changeLoading!: Function;
  @Action("changeAlerta", { namespace: "sistemaModule" })
  changeAlerta!: Function;
  menu: boolean = false;
  menu2: boolean = false;
  desde: string = "";
  hasta: string = "";
  listadoHacienda: Array<HaciendaItem> = [];
  haciendaId: Array<number> = [];
  numeroDespacho: string = "";
  plantaId: number = 0;
  componentName: string = "";
  componenteOpen: number = 1;
  componentModel: boolean = false;
  //#region Propiedades de la tabla
  header: { text: string; sortable: boolean; value: string }[] = [
    { text: "Formato", sortable: true, value: "formatoName" },
    { text: "Planta", sortable: true, value: "plantaName" },
    { text: "Hacienda", sortable: true, value: "haciendaName" },
    { text: "Fecha Recepcion", sortable: true, value: "fechaRecepcion" },
    { text: "Fecha Despacho", sortable: true, value: "fechaDespacho" },
    { text: "# Despacho", sortable: false, value: "numeroDespacho" },
    { text: "Cod. Unico", sortable: false, value: "codigoUnico" },
    { text: "Guia Despacho", sortable: false, value: "guiaRemision" },
    { text: "Prov. Tumba/Aserrado", sortable: false, value: "nombreAserrador" },
    { text: "P.O.", sortable: false, value: "po" },
    { text: "Camion", sortable: true, value: "placa" },
    { text: "V. Enviado", sortable: true, value: "volumenDespachado" },
    { text: "V. Recibido", sortable: true, value: "volumenRecibido" },
    { text: "% Rechazo", sortable: true, value: "porcentajeCalificacion" },
    { text: "V. Rechazado", sortable: true, value: "volumenRechazado" },
    { text: "V. Calificado", sortable: true, value: "volumenCalificado" },
    { text: "V. Pagar", sortable: true, value: "volumenPagar" },
    { text: "Documento Sap", sortable: true, value: "documentoSap" },
    { text: "Mensaje Sap", sortable: true, value: "mensajeSap" },
    { text: "Action", sortable: true, value: "actions" },
  ];
  headerDetalle: { text: string; sortable: boolean; value: string }[] = [
    { text: "Largo", sortable: true, value: "largoName" },
    { text: "Espesor", sortable: true, value: "espesorName" },
    { text: "Ancho", sortable: false, value: "anchoName" },
    { text: "# Plantilla", sortable: true, value: "numeroPlantillas" },
    { text: "Pallet Visual", sortable: true, value: "palletIdVisual" },
    { text: "Fecha Tumba", sortable: true, value: "fechaTumba" },
    { text: "Voumen", sortable: true, value: "volumen" },
  ];

  listado: Array<RecepcionReporteKtb> = [];
  listadoDetalle: Array<RecepcionReporteKtbDetalle> = [];
  //#endregion
  //#region Eventos
  closeDesde(fecha: string) {
    this.menu = false;
    this.desde = fecha;
  }
  closeHasta(fecha: string) {
    this.hasta = fecha;
    this.menu2 = false;
  }

  async generarSapKtb(item: RecepcionReporteKtb) {
    try {
      this.changeLoading(
        new Loading(true, "Generando sap... Espere por favor ")
      );
      var confirmarSap = new ConfirmarTablonModal(item.recepcionId);

      var response = await GenerapSapKtb(confirmarSap);
      console.log(response);
      if (response.isSuccess) {
        this.changeAlerta(
          new Alerta(response.isMessage, true, "success", 3000)
        );
      } else {
        this.changeAlerta(new Alerta(response.isMessage, true, "danger", 3000));
        return;
      }
    } catch (error) {
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }

  async obtenerRecepcion() {
    try {
      this.changeLoading(
        new Loading(true, "Obteniendo informacion...Espere por favor")
      );
      var filtro = new DespachoReporteQuery();
      filtro.desde = this.desde;
      filtro.hasta = this.hasta;
      filtro.numeroDespacho = this.numeroDespacho;
      filtro.haciendaId = this.haciendaId;
      filtro.plantaId = this.plantaId;
      var response = await ObtenerRecepcionFecha(filtro);
      if (response.isSuccess) {
        this.listado = response.isResult;
      } else {
        this.changeAlerta(new Alerta(response.isMessage, true, "danger", 3000));
      }
    } catch (error) {
      this.changeAlerta(new Alerta(String(error), true, "danger", 3000));
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }

  async openPallet(item: RecepcionReporteKtb) {
    try {
      this.changeLoading(new Loading(true, "Obteniendo detalle"));
      var response = await ObtenerRecepcionDetalle(
        item.recepcionId,
        item.formatoId
      );
      if (response.isSuccess) {
        this.listadoDetalle = response.isResult;
        this.componentName = "tabla";
        this.componentModel = true;
      }
    } catch (error) {
      this.changeAlerta(new Alerta(String(error), true, "danger", 3000));
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }
  //#endregion
  async mounted() {
    this.plantaId = this.plantaAsignada[0].plantaId;
    this.desde = moment(new Date()).format("YYYY-MM-DD");
    this.hasta = moment(new Date()).format("YYYY-MM-DD");
    var response = await HaciendaItem.ObtenerHaciendaRecepction(
      this.plantaId,
      1
    );
    if (response.isSuccess) {
      this.listadoHacienda = response.isResult;
    }
  }
  async exportarExcel() {
    try {
      this.changeLoading(new Loading(true, "Exportando a excel"));
      var response = await DescargarExcelDespacho(this.listado);
      let blob = new Blob([response], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        url = window.URL.createObjectURL(blob);
      window.open(url);
    } catch (error) {
      this.changeAlerta(new Alerta(String(error), true, "danger", 3000));
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }
  async exportarExcelDetalle(item: RecepcionReporteKtb) {
    try {
      this.changeLoading(new Loading(true, "Exportando a excel"));
      var response = await DescargarExcelDespachoDetalle(item);
      let blob = new Blob([response], {
          type:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        }),
        url = window.URL.createObjectURL(blob);
      window.open(url);
    } catch (error) {
      this.changeAlerta(new Alerta(String(error), true, "danger", 3000));
    } finally {
      this.changeLoading(new Loading(false, ""));
    }
  }
  //#region Computed
  get componentProperties() {
    if (this.componentName == "tabla") {
      return {
        headers: this.headerDetalle,
        items: this.listadoDetalle,
        busqueda: false,
      };
    }
  }
  //#endregion
}
